import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/BlogLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Last year I wrote about how `}<a parentName="p" {...{
        "href": "../how-i-launched-six-side-projects-in-2017"
      }}>{`I launched six side projects`}</a>{` in one year. I won’t lie, it was a rush to ship that many projects in a short space of time. I don’t regret doing it… but did it pay off in the long run? Stay tuned.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "590px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/31c9c8337ca928832b479224cd4f6af2/ba579/scarface.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62.83783783783784%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAYCAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABxpsF0LYzFf/EABkQAAMBAQEAAAAAAAAAAAAAAAACAwERFP/aAAgBAQABBQIbDzVJxQyM0bp//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAgIDAAAAAAAAAAAAAAAAABECEAESMv/aAAgBAQAGPwKuBo2jFZr/xAAdEAACAgEFAAAAAAAAAAAAAAAAAREhQTFRYaGx/9oACAEBAAE/IXpKHVDprfJPeXIu+nA2+QL3B//aAAwDAQACAAMAAAAQrw//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPxAn/8QAHBABAAMAAwEBAAAAAAAAAAAAAQARITFBUXGh/9oACAEBAAE/EAgcW0xjAEg9RTAkF8LPuzITwXOQ412whtFUP4Oob4W+z//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Me and my side projects in 2017",
            "title": "Me and my side projects in 2017",
            "src": "/static/31c9c8337ca928832b479224cd4f6af2/1c72d/scarface.jpg",
            "srcSet": ["/static/31c9c8337ca928832b479224cd4f6af2/a80bd/scarface.jpg 148w", "/static/31c9c8337ca928832b479224cd4f6af2/1c91a/scarface.jpg 295w", "/static/31c9c8337ca928832b479224cd4f6af2/1c72d/scarface.jpg 590w", "/static/31c9c8337ca928832b479224cd4f6af2/a8a14/scarface.jpg 885w", "/static/31c9c8337ca928832b479224cd4f6af2/fbd2c/scarface.jpg 1180w", "/static/31c9c8337ca928832b479224cd4f6af2/ba579/scarface.jpg 1680w"],
            "sizes": "(max-width: 590px) 100vw, 590px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span><em parentName="p">{`Me and my side projects in 2017`}</em></p>
    <p>{`After last year, I learned more viscerally that there’s a big difference between pleasure and happiness in regards to my work.`}</p>
    <p>{`⚠️`}<em parentName="p">{`Warning: neurological oversimplification ahead`}</em></p>
    <p><strong parentName="p">{`Pleasure is dopamine:`}</strong>{` it’s biological and temporary. You can obtain pleasure from many things: food, sex, drugs, and yes, launching side projects.`}</p>
    <p>{`Dopamine is a helpful tool when it rewards the right kind of behavior. It gives you a way to “hack” your motivation or change habits. The flip side of this is that if your dopamine hits are rewarding the wrong behaviors, it can lead to all kinds of problems: addiction, poor health, depression, etc. Even when used healthily, dopamine is always temporary and requires continual “hits” to maintain a feeling of satisfaction with increasingly diminishing returns.`}</p>
    <p><strong parentName="p">{`Happiness, on the other hand, is more like serotonin.`}</strong>{` It’s also a neurological good feeling, but it works much more slowly. Serotonin is present when you feel significant and useful.`}</p>
    <p>{`Low serotonin levels lead to depression and working to balance it out is one of the first things psychologists will do for those that are clinically depressed (usually with medication).`}</p>
    <p>{`Unfortunately, much of the “hustle culture” around side projects and startups is more centered around dopamine hits:`}</p>
    <blockquote>
      <p parentName="blockquote">{`“ship it!” (`}<em parentName="p">{`snort)`}</em><br parentName="p"></br>{`
`}{`“crush it!” (`}<em parentName="p">{`sniff)`}</em><br parentName="p"></br>{`
`}{`“close it!” `}<em parentName="p">{`(long drag)`}</em><br parentName="p"></br>{`
`}{`…`}</p>
    </blockquote>
    <p>{`It’s no wonder founder burnout and depression is `}<a parentName="p" {...{
        "href": "https://techcrunch.com/2018/12/30/investors-and-entrepreneurs-need-to-address-the-mental-health-crisis-in-startup-culture"
      }}>{`so disproportionally high`}</a>{`.`}</p>
    <p>{`Ideally, your work is a healthy mix of positive reinforcement and long-term happiness. Where dopamine helps you “ship it”, serotonin helps you stick with it.`}</p>
    <p>{`After all the dopamine side-project hits in 2017, my biggest challenge in 2018 for me was to figure out the long-term, sustainable happiness part of work.`}</p>
    <p>{`So how do you find that serotonin? One word: `}<strong parentName="p">{`meaning`}</strong>{`.`}</p>
    <p>{`Having meaningful work is key to unlocking serotonin and long-term happiness in your work.`}</p>
    <h1>{`How to find meaningful work`}</h1>
    <p>{`Most people spend 20–30% of their lives working. If that work is not meaningful, low self-esteem and general unhappiness are highly likely.`}</p>
    <p>{`Trying to maintain a “work-life balance” is an attempt to combat this disconnect but it doesn’t address the root issue: If your work is void of meaning, that portion of your life `}<em parentName="p">{`will`}</em>{` make you unhappy.`}</p>
    <p>{`The good news is that almost all work can have meaning. Even if you’re stuck in a job you don’t like.`}</p>
    <p>{`Here are three steps I’ve found helpful for getting meaning out of work.`}</p>
    <h2>{`Identify your motivations`}</h2>
    <p>{`No matter what the scale, having an underlying motivation that is value-driven and not temporal is important and often lacking in the modern workplace.`}</p>
    <p>{`My favorite way to do this to use the Click-Down technique. It’s very simple and effective. You start by asking a top-level question (e.g. “why am I building project X?”) and then “click down” on it by asking “why?” until you start repeating yourself. Once you’ve hit that point, you’ve identified the core value.`}</p>
    <p>{`You can read more about how to use the Click Down technique `}<a parentName="p" {...{
        "href": "https://medium.com/@wmdmark/how-to-identify-your-core-value-using-the-click-down-technique-feeeeb0b7bfd"
      }}>{`here`}</a>{`.`}</p>
    <h2>{`Find the right type of work`}</h2>
    <p>{`If you have the means, whether it’s your existing job or a side project, find projects that map to your motivations and values.`}</p>
    <p>{`The best kind of meaningful work has the following characteristics in my experience.`}</p>
    <p><strong parentName="p">{`👉Work that gives you control:`}</strong>{` It’s difficult to feel important when your work is prescriptive and without visible impact. Being a cog in the wheel is not a humane role to be in. Unfortunately, much of modern culture (and education) has been optimized for factory-style jobs disconnected from a higher purpose so having meaningful work is the exception, not the rule.`}</p>
    <p>{`This is where working on a side project, no matter how small, can help you find work that is meaningful to you. And, who knows? That small side thing might turn into your main job at some point. Many of the best companies and inventions in history have started as small side projects. My company, `}<a parentName="p" {...{
        "href": "https://www.pathwright.com"
      }}>{`Pathwright`}</a>{`, started as a side project and has grown to become a real business with many happy customers.`}</p>
    <p><strong parentName="p">{`👉Work that challenges you:`}</strong>{` A problem that keeps you learning is going to be more significant to you over time since it will, by necessity, lead to personal growth. It’s commonplace when entrepreneurs sell their business, Ph.D. students finish their thesis, Olympic athletes that win their last medal, etc. to feel a much less happy, even depressed, after the challenge is over. Most of the time they’ll find new things to challenge them.`}</p>
    <p>{`It’s an unsatisfying state to be unchallenged by work especially once you’ve had a taste for the growth that comes through tackling something truly challenging.`}</p>
    <p>{`There’s a more profound existential element to this as well: no one wants their work to truly be finished. We need to work on something that is ongoing and will outlast us. As Umberto Eco says:`}</p>
    <blockquote>
      <p parentName="blockquote">{`We Like Lists Because We Don’t Want to Die — Umberto Eco`}</p>
    </blockquote>
    <p><strong parentName="p">{`👉 Work that is bigger than you:`}</strong>{` You need to have an underlying motivation that is greater than yourself to find true meaning in work. This doesn’t mean it has to be epic or world-changing; it can simply be that the result of your labor is additive to others and not just to yourself.`}</p>
    <p>{`This ties back to your core values and motivations as discussed earlier. Look for projects that both match those values and help others and I guarantee you’ll be much happier for it. You’ll likely do much better work as well.`}</p>
    <h2>{`Benefits`}</h2>
    <p>{`The great thing about finding meaningful work is that you can’t lose. Not only are you more likely to do better work and stick with it (which often leads to monetary benefits), your overall happiness will be more stable.`}</p>
    <p>{`Once you’ve found your meaningful project, it’s now time to use that dopamine to “hustle” and “ship it” or whatever you want to call it but do so with a spirit of gratitude that you are one of the lucky ones.`}</p>
    <p>{`Time is the most valuable currency we have; spend it on things that make you happy. I’ll wrap this up by sharing this extended quote from Steve Jobs that summarizes the ideas above nicely:`}</p>
    <blockquote>
      <p parentName="blockquote">{`You’ve got to find what you love. And that is as true for your work as it is for your lovers. Your work is going to fill a large part of your life, and the only way to be truly satisfied is to do what you believe is great work. And the only way to do great work is to love what you do. If you haven’t found it yet, keep looking. Don’t settle. As with all matters of the heart, you’ll know when you find it. And, like any great relationship, it just gets better and better as the years roll on. So keep looking until you find it. Don’t settle…`}</p>
      <p parentName="blockquote">{`Your time is limited, so don’t waste it living someone else’s life. Don’t be trapped by dogma — which is living with the results of other people’s thinking. Don’t let the noise of others’ opinions drown out your own inner voice. And most important, have the courage to follow your heart and intuition. They somehow already know what you truly want to become. Everything else is secondary.”`}</p>
    </blockquote>
    <p>{`You can watch the whole speech, which I highly recommend doing, `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=D1R-jKKp3NA"
      }}>{`here`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      